@import "../../styles/variables.scss";
.container {
	display: flex;
	min-height: 100%;

	font-family: $font;
	flex-direction: column;
	justify-content: flex-end;
}

.barContainer {
	// align-self: flex-end;
	display: flex;
}

.header {
	padding: 18px 24px;
	font-size: 14px;
	width: 100%;
	position: fixed;
	display: flex;
	justify-content: space-between;
	top: 0;

	.title {
		font-size: 20px;
		font-weight: 300;
		line-height: 24px;
		letter-spacing: 0.02em;
		max-width: 183px;
	}
	.count {
		font-size: 50px;
		line-height: 45px;
	}
}

.noData {
	display: flex;
	color: rgba($dark, 0.8);
	align-items: center;
	margin-bottom: 50%;
	justify-content: center;
}

.widgetLoading {
	:global(.ant-spin-container) {
		height: 100%;
	}
}
